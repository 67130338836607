import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Components from "./views/Components.vue";
import Habit from "./views/Habit.vue"
import Login from "./views/Login.vue";
import MainPage from "./views/MainPage.vue";
import MessageBoard from "./views/MessageBoard.vue";
import Register from "./views/Register.vue";
import Profile from "./views/Profile.vue";
import Rayray from "./views/Rayray.vue";
import Lego from "./views/portfolios/Lego.vue";
import China from "./views/portfolios/China.vue";
import California from "./views/portfolios/California.vue";
import NewYork from "./views/portfolios/NewYork.vue";
import NewOrleans from "./views/portfolios/NewOrleans.vue";
import Tahoe from "./views/portfolios/Tahoe.vue";
import LosAngeles from "./views/portfolios/LosAngeles.vue";
import LasVegas from "./views/portfolios/LasVegas.vue";
import Song from "./views/Song.vue";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "mainpage",
      components: {
        header: AppHeader,
        default: MainPage,
        footer: AppFooter
      }
    },
    {
      path: "/habit",
      name: "habit",
      components: {
        header: AppHeader,
        default: Habit,
        footer: AppFooter
      }
    },
    {
      path: "/login",
      name: "login",
      components: {
        header: AppHeader,
        default: Login,
        footer: AppFooter
      }
    },
    {
      path: "/message_board",
      name: "message_board",
      components: {
        header: AppHeader,
        default: MessageBoard,
        footer: AppFooter
      }
    },
    {
      path: "/components",
      name: "components",
      components: {
        header: AppHeader,
        default: Components,
        footer: AppFooter
      }
    },
    {
      path: "/register",
      name: "register",
      components: {
        header: AppHeader,
        default: Register,
        footer: AppFooter
      }
    },
    {
      path: "/profile",
      name: "profile",
      components: {
        header: AppHeader,
        default: Profile,
        footer: AppFooter
      }
    },
    {
      path: "/rayray",
      name: "rayray",
      components: {
        header: AppHeader,
        default: Rayray,
        footer: AppFooter
      }
    },
    {
      path: "/song",
      name: "song",
      components: {
        header: AppHeader,
        default: Song,
        footer: AppFooter
      }
    },
    {
      path: "/lego",
      name: "lego",
      components: {
        header: AppHeader,
        default: Lego,
        footer: AppFooter
      }
    },
    {
      path: "/china",
      name: "china",
      components: {
        header: AppHeader,
        default: China,
        footer: AppFooter
      }
    },
    {
      path: "/california",
      name: "california",
      components: {
        header: AppHeader,
        default: California,
        footer: AppFooter
      }
    },
    {
      path: "/new_york",
      name: "new_york",
      components: {
        header: AppHeader,
        default: NewYork,
        footer: AppFooter
      }
    },
    {
      path: "/new_orleans",
      name: "new_orleans",
      components: {
        header: AppHeader,
        default: NewOrleans,
        footer: AppFooter
      }
    },
    {
      path: "/tahoe",
      name: "tahoe",
      components: {
        header: AppHeader,
        default: Tahoe,
        footer: AppFooter
      }
    },
    {
      path: "/los_angeles",
      name: "los_angeles",
      components: {
        header: AppHeader,
        default: LosAngeles,
        footer: AppFooter
      }
    },
    {
      path: "/las_vegas",
      name: "las_vegas",
      components: {
        header: AppHeader,
        default: LasVegas,
        footer: AppFooter
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
