<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class=" col px-0">
            <div class="row">
              <h1 class="text-white">Rayray W</h1>
            </div>
            <div class="row">
              <h2 class="text-white" style="text-indent: 50px">
                The most naughty, cute and sweet bunny.
              </h2>
            </div>
            <div class="row">
              <h4 class="text-white" style="text-indent: 50px">
                Follow our RED (小红书) to see more!
              </h4>
            </div>
            <div class="row">
              <div class="col-lg-1"></div>
              <div class="col-lg-2">
                <img src="img/rayray/littleredbook.png" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>
    <section class="section bg-secondary">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-favourite-28" class="mb-5" size="lg" type="primary" shadow rounded></icon>
              <h3>Rayray with us</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-3">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-primary text-uppercase"></h4>
                  <img src="img/rayray/onthigh.jpg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-5">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-primary text-uppercase"></h4>
                  <img src="img/rayray/family.jpg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-primary text-uppercase"></h4>
                  <img src="img/rayray/onback.jpg" class="img-fluid" />
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-collection" class="mb-5" size="lg" type="primary" shadow rounded></icon>
              <h3>Rayray Videos</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4">
            <div class="py-3 text-center">
              <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="drinkingOptions"></video-player>
            </div>
          </div>
          <div class="col-md-4">
            <div class="py-3 text-center">
              <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="eatingOptions"></video-player>
            </div>
          </div>
          <div class="col-md-4">
            <div class="py-3 text-center">
              <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playingOptions"></video-player>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-secondary">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-chat-round" class="mb-5" size="lg" type="primary" shadow rounded></icon>
              <h3>Rayray Sleeping</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-primary text-uppercase"></h4>
                  <img src="img/rayray/heart.jpg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-primary text-uppercase"></h4>
                  <img src="img/rayray/sleeping.jpg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-primary text-uppercase"></h4>
                  <img src="img/rayray/lying.jpg" class="img-fluid" />
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-2">
            <img src="img/rayray/w.jpg" class="img-fluid floating" />
          </div>
          <div class="col-md-4 order-md-2">
            <img src="img/rayray/standing.jpg" class="img-fluid floating" />
          </div>
          <div class="col-md-4 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-bell-55" class="mb-5" size="lg" type="primary" shadow rounded></icon>
              <h3>Rayray Standing</h3>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="primary" circle class="mr-3" icon="ni ni-bold-right"></badge>
                    <h6 class="mb-0">He has his last name <b>W</b> on his head!</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="primary" circle class="mr-3" icon="ni ni-bold-right"></badge>
                    <h6 class="mb-0">He is so tall!</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-secondary">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-ambulance" class="mb-5" size="lg" type="primary" shadow rounded></icon>
              <h3>Sick Rayray</h3>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="primary" circle class="mr-3" icon="ni ni-bold-right"></badge>
                    <h6 class="mb-0">Sick Rayray.</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="primary" circle class="mr-3" icon="ni ni-bold-right"></badge>
                    <h6 class="mb-0">We were super worried.</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="primary" circle class="mr-3" icon="ni ni-bold-right"></badge>
                    <h6 class="mb-0">He is worth at least $3000 now.</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-5 order-md-2">
            <img src="img/rayray/medicine.jpg" class="img-fluid floating" />
          </div>
          <div class="col-md-3 order-md-2">
            <img src="img/rayray/sick.jpg" class="img-fluid floating" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import {
    videoPlayer
} from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

export default {
  name: "rayray",
  components: {
    Modal,
    videoPlayer
  },
  data() {
    return {
      modal: false,
      defaultPlayerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // play speed
        autoplay: true, // play when your browser is ready
        muted: true, // whether to mute by default
        loop: true, // restart when it ends
        preload: 'auto', // whether to download when parsing <video>
        language: 'zh-CN',
        aspectRatio: '16:9', // proportion
        fluid: true, // suit container in proportion
        sources: [{
          type: "video/mp4", // see github for more types
          src: this.video_src // url of the video
        }],
        poster: this.poster_src, // cover of the video
        // width: document.documentElement.clientWidth, // width of the player
        notSupportedMessage: 'Something went wrong', // default message when the video cannot be played
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true
        }
      }
    };
  },
  computed: {
      totalTime() {
          return Math.round((new Date() - new Date(2023, 7, 24, 0, 0, 0, 0))/1000/60/60/24/365);
      },
      drinkingOptions() {
          return {...this.defaultPlayerOptions, ...{sources: [{type: "video/mp4", src: "img/rayray/drinking.mp4"}], aspectRatio: '9:16'}};
      },
      eatingOptions() {
          return {...this.defaultPlayerOptions, ...{sources: [{type: "video/mp4", src: "img/rayray/eating.mp4"}], aspectRatio: '9:16'}};
      },
      playingOptions() {
          return {...this.defaultPlayerOptions, ...{sources: [{type: "video/mp4", src: "img/rayray/playing.mp4"}], aspectRatio: '9:16'}};
      }
  }
};
</script>
